import { bindable, bindingMode } from 'aurelia-framework';

export class ListDetailedClassifications {

    listingId = 'legislation-diplomas-detailed-classifications-listing';

    @bindable({defaultBindingMode: bindingMode.twoWay}) readonly;
    @bindable({defaultBindingMode: bindingMode.twoWay}) detailedClassifications;
    @bindable({defaultBindingMode: bindingMode.twoWay}) classificationsTree;

    @bindable datatable;

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:  (criteria) => Promise.resolve({
                    data:            this.detailedClassifications,
                    recordsTotal:    this.detailedClassifications.length,
                    recordsFiltered: this.detailedClassifications.length,
                }),
                destroy: null,
            },
            destroySelected:     false,
            actions:             [
                {
                    icon:    'icon-trash',
                    action:  (row, $index) => this.unlink(row, $index),
                    tooltip: 'form.button.delete',
                },
            ],
            options:             [],
            buttons:             [],
            sorting:             {
                column:    0,
                direction: 'asc',
            },
            selectable:          false,
            showFilter:          false,
            showOptionsDropdown: false,
            showResultsPerPage:  false,
            showInfo:            false,
            showPagination:      false,
            columns:             [
                {
                    data:      'name',
                    name:      'name',
                    title:     'form.field.name',
                    orderable: false,
                },
            ],
        };
    }

    /**
     * Removes a record from the array
     *
     * @param row
     * @param index
     */
    unlink(row, index) {
        this.detailedClassifications.splice(index, 1);
        this.classificationsTree.deselectNodeByKey(row.id);
    }

}
