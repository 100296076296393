import { inject }                           from 'aurelia-framework';
import { Diploma }                          from 'modules/legislation/models/diploma';
import { DiplomaStatus }                    from 'modules/legislation/models/diploma-status';
import { DiplomasRepository }               from 'modules/legislation/diplomas/services/repository';
import { CountriesRepository }              from 'modules/administration/countries/services/repository';
import { SourcesRepository }                from 'modules/legislation/management/sources/services/repository';
import { DiplomaTypesRepository }           from 'modules/legislation/management/diploma-types/services/repository';
import { DiplomaStatusesRepository }        from 'modules/legislation/management/diploma-statuses/services/repository';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';
import { I18N }                             from 'aurelia-i18n';
import moment                               from 'moment';

@inject(DiplomasRepository, CountriesRepository, SourcesRepository, DiplomaTypesRepository, DiplomaStatusesRepository, DiplomaClassificationsRepository, I18N)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: DiplomaStatus.OPERATIVE,
    };

    /**
     * Constructor
     *
     * @param diplomasRepository
     * @param countriesRepository
     * @param sourcesRepository
     * @param diplomaTypesRepository
     * @param diplomaStatusesRepository
     * @param diplomaClassificationsRepository
     */
    constructor(diplomasRepository, countriesRepository, sourcesRepository, diplomaTypesRepository, diplomaStatusesRepository, diplomaClassificationsRepository, i18n) {
        this.diplomasRepository               = diplomasRepository;
        this.countriesRepository              = countriesRepository;
        this.sourcesRepository                = sourcesRepository;
        this.diplomaTypesRepository           = diplomaTypesRepository;
        this.diplomaStatusesRepository        = diplomaStatusesRepository;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
        this.i18n                             = i18n;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Diploma}
     */
    model(viewModel) {
        let model = new Diploma();

        model.assign(this.modelDefaults);

        model.country_id = viewModel.settings.default_country_id || null;
        model.source_id  = viewModel.settings.default_source_id || null;

        return model;
    }

    /**
     * Returns general form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    generalSchema(viewModel) {
        this.commencement_date = {
            type:     'material-ui-date-picker',
            key:      'commencement_date',
            label:    'form.field.commencement-date',
            size:     4,
            required: false,
            settings: {
                selectYears:  100,
                selectMonths: true,
            },
        };

        this.internal_code = {
            type:     'text',
            key:      'internal_code',
            label:    'form.field.internal-code',
            size:     4,
            required: false,
        };

        this.country_id = {
            type:         'select2',
            key:          'country_id',
            label:        'form.field.country',
            size:         4,
            options:      [],
            remoteSource: this.countriesRepository.active.bind(this.countriesRepository),
            observers:    [
                () => this.source_id.instance.fetchData().then((options) => this.source_id.instance.enable(options.length > 0)),
            ],
        };

        this.source_id = {
            type:                   'select2',
            key:                    'source_id',
            label:                  'form.field.source',
            size:                   4,
            options:                [],
            remoteSource:           this.sourcesRepository.activeByCountry.bind(this.sourcesRepository),
            remoteSourceParameters: () => viewModel.model.country_id,
            observers:              [
                () => this.type_id.instance.fetchData().then((options) => this.type_id.instance.enable(options.length > 0)),
            ],
            attributes:             {
                disabled: !isNumeric(viewModel.model.country_id),
            },
        };

        this.type_id = {
            type:                   'select2',
            key:                    'type_id',
            label:                  'form.field.type',
            size:                   4,
            options:                [],
            remoteSource:           this.diplomaTypesRepository.activeBySources.bind(this.diplomaTypesRepository),
            remoteSourceParameters: () => [viewModel.model.source_id],
            attributes:             {
                disabled: !isNumeric(viewModel.model.source_id),
            },
            observers:              [
                () => this.updateDiplomaName(viewModel),
            ],
        };

        this.number = {
            type:      'text',
            key:       'number',
            label:     'form.field.number',
            size:      2,
            observers: [
                () => this.updateDiplomaName(viewModel),
            ],
        };

        this.published_at = {
            type:      'material-ui-date-picker',
            key:       'published_at',
            label:     'form.field.published-at',
            size:      4,
            settings:  {
                selectYears:  100,
                selectMonths: true,
            },
            observers: [
                () => this.updateDiplomaName(viewModel),
            ],
        };

        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.name',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.issuing_entity = {
            type:     'text',
            key:      'issuing_entity',
            label:    'form.field.issuing-entity',
            size:     12,
            required: false,
        };

        this.notes = {
            type:     'textarea',
            key:      'notes',
            label:    'form.field.notes',
            size:     12,
            required: false,
        };

        this.keywords = {
            type:     'tags',
            key:      'keywords',
            label:    'form.field.keywords',
            size:     12,
            required: false,
        };

        this.viewing_document_file = {
            type:     'file-dropzone',
            key:      'viewing_document_file_id',
            label:    'form.field.viewing-document',
            size:     12,
            url:      this.diplomasRepository.fileUploadUri(),
            mockFile: viewModel.model.existing_viewing_document_file,
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            options:      [],
            remoteSource: this.diplomaStatusesRepository.active.bind(this.diplomaStatusesRepository),
        };

        return [
            [this.commencement_date, this.internal_code],
            [this.country_id, this.source_id, this.type_id],
            [this.number, this.published_at, this.name],
            [this.issuing_entity],
            [this.notes],
            [this.keywords],
            [this.viewing_document_file],
            [this.status_id],
        ];
    }

    /**
     * Returns summary form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    summarySchema(viewModel) {
        this.summary = {
            type:  'textarea',
            key:   'summary',
            label: 'form.field.summary',
            size:  12,
        };

        return [
            [this.summary],
        ];
    }

    /**
     * Returns classification form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    classificationsSchema(viewModel) {
        this.classifications = {
            type:         'fancytree',
            key:          'classifications',
            label:        'form.field.classifications',
            size:         12,
            options:      [],
            remoteSource: this.diplomaClassificationsRepository.tree.bind(this.diplomaClassificationsRepository, true),
            settings:     {
                filterable:       true,
                repository:       this.diplomaClassificationsRepository,
                treeNodeSelected: (event, data) => {
                    let detailedClassifications = [];

                    let selectedNodes = data.tree.getSelectedNodes();

                    for (let i = 0; i < selectedNodes.length; i++) {
                        detailedClassifications.push({
                            id:   selectedNodes[i].key,
                            name: this.nodePath(selectedNodes[i]),
                        });
                    }

                    viewModel.model.detailed_classifications.splice(0, viewModel.model.detailed_classifications.length, ...detailedClassifications);
                },
            },
        };

        return [
            [this.classifications],
        ];
    }

    /**
     * Returns classification form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    consolidatedDocumentSchema(viewModel) {
        this.consolidated_document_file = {
            type:     'file-dropzone',
            key:      'consolidated_document_file_id',
            label:    'form.field.consolidated-document',
            size:     12,
            url:      this.diplomasRepository.fileUploadUri(),
            required: false,
            mockFile: viewModel.model.existing_consolidated_document_file,
        };

        return [
            [this.consolidated_document_file],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.jurist.diplomas.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Updates diploma code
     *
     * @param viewModel
     */
    updateDiplomaName(viewModel) {
        viewModel.model.name = null;

        if (viewModel.model.type_id && viewModel.model.number && viewModel.model.published_at) {
            let publishedAt = moment(viewModel.model.published_at);

            let replaces = {
                number: viewModel.model.number,
                type:   this.dropdownSelectedText('type_id').trim(),
                day:    publishedAt.format('DD'),
                month:  publishedAt.format('MMMM'),
                year:   publishedAt.format('YYYY'),
            };

            let translationsSlug =
                    (new RegExp(Diploma.COMMUNITY_CODES.join('|')).test(viewModel.model.number))
                        ? 'text.community-diploma-name-frontend'
                        : 'text.regular-diploma-name-frontend';

            viewModel.model.name = this.i18n.tr(translationsSlug, replaces).replaceAll('&#x2F;', '/');
        }
    }

    /**
     * Returns full path of a node
     *
     * @param node
     *
     * @returns {string}
     */
    nodePath(node) {
        if (node.title === 'root') {
            return '';
        }

        let parentTitle = this.nodePath(node.parent);

        return (parentTitle ? (parentTitle + '<icon class="icon-arrow-right22"></icon>') : '') + node.title;
    }

    /**
     * Returns dropdown selected text
     *
     * @param field
     *
     * @returns {*|jQuery}
     */
    dropdownSelectedText(field) {
        return $('#' + field + ' option:selected').text();
    }

}
