import { bindable, inject }                        from 'aurelia-framework';
import { AppContainer }                            from 'resources/services/app-container';
import { BaseViewModel }                           from 'base-view-model';
import { ConformityEvaluationLinesRepository }     from 'modules/legislation/client/conformity-evaluations/checklist/services/repository';
import { ConformityEvaluationLineFilesRepository } from 'modules/legislation/client/conformity-evaluations/checklist/services/files-repository';
import { DialogController }                        from 'aurelia-dialog';
import { FormSchema }                              from 'modules/legislation/diplomas/conformity/lines/view/form-schema';

@inject(AppContainer, ConformityEvaluationLinesRepository, ConformityEvaluationLineFilesRepository, DialogController, FormSchema)
export class ViewConformityEvaluationModal extends BaseViewModel {

    @bindable settings = {
        title:   'form.title.conformity-evaluation',
        size:    'modal-full',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filesRepository
     * @param formSchema
     * @param dialogController
     */
    constructor(appContainer, repository, filesRepository, dialogController, formSchema) {
        super(appContainer);

        this.repository       = repository;
        this.filesRepository  = filesRepository;
        this.dialogController = dialogController;
        this.formSchema       = formSchema;
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.repository.find(params.id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);

            this.settings.subtitle = response.meta.diploma_name;

            this.diplomaSchema    = this.formSchema.diplomaSchema(this);
            this.conformitySchema = this.formSchema.conformitySchema(this);
        });
    }

}
