import { inject, PLATFORM }          from 'aurelia-framework';
import { AppContainer }              from 'resources/services/app-container';
import { BaseFormViewModel }         from 'base-form-view-model';
import { DiplomasRepository }        from 'modules/legislation/diplomas/services/repository';
import { DiplomaSettingsRepository } from 'modules/legislation/management/diploma-settings/services/repository';
import { FormSchema }                from 'modules/legislation/diplomas/form-schema';

@inject(AppContainer, DiplomasRepository, DiplomaSettingsRepository, FormSchema)
export class EditDiploma extends BaseFormViewModel {

    headerTitle    = 'form.title.edit-record';
    newRecordRoute = 'legislation.diplomas.create';
    formId         = 'legislation-diplomas-edition-form';

    generalSchema              = {};
    classificationsSchema      = {};
    consolidatedDocumentSchema = {};
    globalButtonsSchema        = {};

    alert = {};
    model = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param settingsRepository
     * @param formSchema
     */
    constructor(appContainer, repository, settingsRepository, formSchema) {
        super(appContainer);

        this.repository         = repository;
        this.settingsRepository = settingsRepository;
        this.formSchema         = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/legislation/diplomas/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.jurist.diplomas.manage',
            'legislation.jurist.diplomas.edit',
            'legislation.consultant.diplomas.manage',
            'legislation.consultant.diplomas.edit',
            'legislation.client.diplomas.manage',
            'legislation.client.diplomas.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        return this.settingsRepository
            .settings()
            .then((response) => this.settings = response)
            .then(() => {
                this.initialModel = this.formSchema.model(this);
                this.model        = this.formSchema.model(this);
            })
            .then(() => this.repository.find(params.id))
            .then((response) => {
                this.initialModel.assign(response);
                this.model.assign(response);

                this.generalSchema              = this.formSchema.generalSchema(this);
                this.summarySchema              = this.formSchema.summarySchema(this);
                this.classificationsSchema      = this.formSchema.classificationsSchema(this);
                this.consolidatedDocumentSchema = this.formSchema.consolidatedDocumentSchema(this);
                this.globalButtonsSchema        = this.formSchema.globalButtonsSchema(this);
            });
    }

    /**
     * Default form submitted callback
     *
     * @param response
     * @param redirectRoute
     */
    defaultFormSubmittedCallback(response, redirectRoute) {
        if (response.status === true) {
            // creates a new notifier message
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel = this.model;

            this.redirectToRoute(redirectRoute);
        } else {
            // creates a new alert message
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }
    }

}
