import { bindable, inject }   from 'aurelia-framework';
import { AppContainer }       from 'resources/services/app-container';
import { BaseViewModel }      from 'base-view-model';
import { DiplomasRepository } from 'modules/legislation/diplomas/services/repository';
import { FormSchema }         from 'modules/legislation/diplomas/edit/details/form-schema';
import { DialogController }   from 'aurelia-dialog';

@inject(AppContainer, DiplomasRepository, FormSchema, DialogController)
export class EditClientDiplomaDetailsModal extends BaseViewModel {

    @bindable settings = {
        title: 'form.title.edit-details',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param dialogController
     */
    constructor(appContainer, repository, formSchema, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.formSchema       = formSchema;
        this.dialogController = dialogController;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.jurist.diplomas.manage',
            'legislation.jurist.diplomas.edit',
            'legislation.consultant.diplomas.manage',
            'legislation.consultant.diplomas.edit',
            'legislation.client.diplomas.manage',
            'legislation.client.diplomas.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(diploma, navigationInstruction) {
        super.activate(navigationInstruction);

        this.diploma = diploma;

        // defines diploma name as the modal subtitle
        this.settings.subtitle = this.diploma.name;

        return this.fetchData().then(() => {
            this.model  = this.formSchema.model();
            this.schema = this.formSchema.schema(this);

            this.model.assign(this.diploma);
        });
    }

    /**
     * Submits form
     */
    submit() {
        return this.repository.updateClientDiplomaDetails(this.diploma.id, this.model).then((response) => {
            this.alert = {};

            if (response.status === true) {
                this.diploma.resume        = this.model.resume;
                this.diploma.internal_code = this.model.internal_code;
                this.diploma.keywords      = this.model.keywords;
                this.diploma.notes         = this.model.notes;

                this.appContainer.notifier.successNotice();
            } else {
                this.alert = this.alertMessage(response.status, response.message, response.errors);
            }

            return response.status;
        });
    }

}
