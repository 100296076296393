import { bindable, inject, PLATFORM }       from 'aurelia-framework';
import { AppContainer }                     from 'resources/services/app-container';
import { BaseFormViewModel }                from 'base-form-view-model';
import { DiplomaApplicabilitiesRepository } from 'modules/legislation/diplomas/applicability/services/repository';
import { ActionPlanListing }                from 'modules/legislation/diplomas/applicability/action-plan/index';
import { DialogService }                    from 'aurelia-dialog';

@inject(AppContainer, DiplomaApplicabilitiesRepository, DialogService)
export class ListDiplomaApplicabilitiesCustomElement extends BaseFormViewModel {

    formId = 'legislation-diplomas-applicability-listing';

    @bindable diploma;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogService
     */
    constructor(appContainer, repository, dialogService) {
        super(appContainer);

        this.repository    = repository;
        this.dialogService = dialogService;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search: (criteria) => this.repository.search(this.diploma.id, criteria),
            },
            edit:                null,
            destroy:             null,
            actions:             [
                {
                    icon:    'icon-clipboard3',
                    tooltip: 'form.button.action-plan',
                    action:  (row) => this.dialogService.open({
                        viewModel: ActionPlanListing,
                        model:     row,
                    }),
                },
            ],
            options:             [],
            buttons:             [],
            selectable:          false,
            destroySelected:     false,
            showOptionsDropdown: false,
            showInfo:            false,
            showPagination:      false,
            sorting:             {
                column:    0,
                direction: 'asc',
            },
            columns:             [
                {
                    data:  'applicable_sector',
                    name:  'company_organizations.acronym',
                    title: 'form.field.applicable-sector',
                },
                {
                    data:  'applicability_type',
                    name:  'applicability_types.name',
                    title: 'form.field.applicability-type',
                },
                {
                    data:       'diploma_classification',
                    name:       'diploma_classifications.name',
                    title:      'form.field.classification',
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:      'applicability_notes',
                    name:      'diploma_applicabilities.applicability_notes',
                    title:     'form.field.applicability-notes',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/applicability-notes/index'),
                    nowrap:    true,
                },
                {
                    data:            'created_at',
                    name:            'diploma_applicabilities.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:  'created_by',
                    name:  'creators.name',
                    title: 'form.field.created-by',
                },
            ],
        };
    }

}
