import { bindable, bindingMode, inject }       from 'aurelia-framework';
import { AppContainer }                        from 'resources/services/app-container';
import { ConformityEvaluationLinesRepository } from 'modules/legislation/diplomas/conformity/lines/services/repository';
import { ViewConformityEvaluationModal }       from 'modules/legislation/diplomas/conformity/lines/view/index';
import { DialogService }                       from 'aurelia-dialog';

@inject(AppContainer, ConformityEvaluationLinesRepository, DialogService)
export class ListDiplomaConformityEvaluationLinesCustomElement {

    @bindable({defaultBindingMode: bindingMode.twoWay}) diploma;
    @bindable({defaultBindingMode: bindingMode.twoWay}) conformityEvaluation;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogService
     */
    constructor(appContainer, repository, dialogService) {
        this.appContainer = appContainer;

        this.repository    = repository;
        this.dialogService = dialogService;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:  (criteria) => this.repository.search(this.diploma.id, this.conformityEvaluation.id, criteria),
                destroy: null,
            },
            show:                {
                action: (row) => this.dialogService.open({
                    viewModel: ViewConformityEvaluationModal,
                    model:     row,
                }),
            },
            actions:             [],
            options:             [],
            buttons:             [],
            selectable:          false,
            destroySelected:     false,
            showOptionsDropdown: false,
            showInfo:            false,
            showPagination:      false,
            sorting:             {
                column:    0,
                direction: 'asc',
            },
            columns:             [
                {
                    data:  'diploma_classification_name',
                    name:  'diploma_classifications.name',
                    title: 'form.field.classification',
                },
                {
                    data:  'applicable_sector_name',
                    name:  'company_organizations.name',
                    title: 'form.field.applicable-sector',
                },
                {
                    data:  'applicability_type_name',
                    name:  'applicability_types.name',
                    title: 'form.field.applicability-type',
                },
                {
                    data:       'status',
                    name:       'conformity_evaluation_statuses.name',
                    title:      'form.field.status',
                    type:       'stoplight',
                    icon_eval:  (row) => row.status ? row.status.icon : null,
                    color_eval: (row) => 'text-primary',
                },
            ],
        };
    }

}
