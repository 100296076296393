import { bindable, inject }  from 'aurelia-framework';
import { AppContainer }      from 'resources/services/app-container';
import { BaseFormViewModel } from 'base-form-view-model';
import { FormSchema }        from 'modules/legislation/diplomas/legal-connections/edit/form-schema';
import { DialogController }  from 'aurelia-dialog';

@inject(AppContainer, FormSchema, DialogController)
export class EditDiplomaLegalConnection extends BaseFormViewModel {

    @bindable settings = {
        title:   'form.title.edit-legal-connection',
        size:    'modal-lg',
        buttons: true,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param dialogController
     */
    constructor(appContainer, formSchema, dialogController) {
        super(appContainer);

        this.formSchema       = formSchema;
        this.dialogController = dialogController;
    }

    /**
     * Handles bind event
     */
    activate(model) {
        this.diploma         = model.diploma;
        this.legalConnection = model.legalConnection;

        this.model  = this.formSchema.model(this);
        this.schema = this.formSchema.schema(this);

        this.formSchema.manual_owned_diploma.attributes.hidden = this.legalConnection.owned_diploma_id > 0;

        if (this.diploma.name) {
            this.settings.title = `${this.diploma.name} - ${this.appContainer.i18n.tr('form.title.edit-legal-connection')}`;
        }

        this.model.assign(this.legalConnection);
    }

    /**
     * Submits the form
     */
    submit() {
        return new Promise((resolve, reject) => {
            this.legalConnection.legal_connection_type_name = this.dropdownSelectedText('diploma_legal_connections_legal_connection_type_id');
            this.legalConnection.legal_connection_type_id   = this.model.legal_connection_type_id;
            this.legalConnection.commencement_date          = this.model.commencement_date;
            this.legalConnection.notes                      = this.model.notes;
            this.legalConnection.manual_owned_diploma       = this.model.manual_owned_diploma;

            if (this.legalConnection.manual_owned_diploma) {
                this.legalConnection.owned_diploma_name = this.legalConnection.manual_owned_diploma;
            }

            resolve(true);
        });
    }

}
