import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { DiplomasRepository }            from 'modules/legislation/diplomas/services/repository';
import { DialogService }                 from 'aurelia-dialog';

@inject(AppContainer, DiplomasRepository, DialogService)
export class ListDiplomaConformityEvaluationsCustomElement {

    @bindable({defaultBindingMode: bindingMode.twoWay}) diploma;
    @bindable({defaultBindingMode: bindingMode.twoWay}) lastConformityEvaluation;
    @bindable({defaultBindingMode: bindingMode.twoWay}) conformityEvaluations = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param diplomasRepository
     * @param dialogService
     */
    constructor(appContainer, diplomasRepository, dialogService) {
        this.appContainer = appContainer;

        this.diplomasRepository = diplomasRepository;
        this.dialogService      = dialogService;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.diplomasRepository.conformityEvaluations(this.diploma.id).then((response) => {
            this.lastConformityEvaluation = response.shift();
            this.conformityEvaluations    = response;
        });
    }

}
