import { bindable, inject }   from 'aurelia-framework';
import { AppContainer }       from 'resources/services/app-container';
import { BaseViewModel }      from 'base-view-model';
import { DiplomasRepository } from 'modules/legislation/diplomas/services/repository';
import { DialogController }   from 'aurelia-dialog';

@inject(AppContainer, DiplomasRepository, DialogController)
export class ViewDiplomaModal extends BaseViewModel {

    @bindable settings = {
        title:   'form.title.view-record',
        size:    'modal-full',
        buttons: false,
    };

    backButtonRoute = null;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     */
    constructor(appContainer, repository, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.jurist.diplomas.manage',
            'legislation.jurist.diplomas.view',
            'legislation.consultant.diplomas.manage',
            'legislation.consultant.diplomas.view',
            'legislation.client.diplomas.manage',
            'legislation.client.diplomas.view',
            'legislation.client.personal-area.diplomas.manage',
            'legislation.client.personal-area.diplomas.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        return this.repository.details(params.id).then((response) => {
            this.diploma = response;

            // use diploma name as the modal title
            this.settings.title = this.diploma.name;
        });
    }

}
