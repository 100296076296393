import { inject } from 'aurelia-framework';
import { I18N }   from 'aurelia-i18n';
import collect    from 'collect.js';

@inject(I18N)
export class DiplomaLegalConnections {

    /**
     * Constructor
     *
     * @param i18n
     */
    constructor(i18n) {
        this.i18n = i18n;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.column  = model.column;
        this.diploma = model.row;

        this.groupedLegalConnections = collect(this.diploma.legal_connections).groupBy((item) => item.legal_connection_type.name);
    }

}
