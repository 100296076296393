import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ConformityEvaluationLinesRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Performs a search given some criteria
     *
     * @param diplomaId
     * @param conformityEvaluationId
     * @param criteria
     *
     * @returns {*}
     */
    search(diplomaId, conformityEvaluationId, criteria) {
        return this.httpClient.post(`legislation/diplomas/${diplomaId}/conformity-evaluations/${conformityEvaluationId}/lines/search`, criteria);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`legislation/diplomas/conformity-evaluations/lines/${id}`);
    }

}
