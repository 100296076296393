import { inject, PLATFORM }          from 'aurelia-framework';
import { AppContainer }              from 'resources/services/app-container';
import { BaseFormViewModel }         from 'base-form-view-model';
import { DiplomasRepository }        from 'modules/legislation/diplomas/services/repository';
import { DiplomaSettingsRepository } from 'modules/legislation/management/diploma-settings/services/repository';
import { FormSchema }                from 'modules/legislation/diplomas/form-schema';

@inject(AppContainer, DiplomasRepository, DiplomaSettingsRepository, FormSchema)
export class CreateDiploma extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'legislation-diplomas-creation-form';

    generalSchema              = {};
    classificationsSchema      = {};
    consolidatedDocumentSchema = {};
    globalButtonsSchema        = {};

    alert = {};
    model = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param settingsRepository
     * @param formSchema
     */
    constructor(appContainer, repository, settingsRepository, formSchema) {
        super(appContainer);

        this.repository         = repository;
        this.settingsRepository = settingsRepository;
        this.formSchema         = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/legislation/diplomas/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.jurist.diplomas.manage',
            'legislation.jurist.diplomas.create',
            'legislation.consultant.diplomas.manage',
            'legislation.consultant.diplomas.create',
            'legislation.client.diplomas.manage',
            'legislation.client.diplomas.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        return this.settingsRepository.settings()
            .then((response) => this.settings = response)
            .then(() => {
                this.initialModel = this.formSchema.model(this);
                this.model        = this.formSchema.model(this);

                this.generalSchema              = this.formSchema.generalSchema(this);
                this.summarySchema              = this.formSchema.summarySchema(this);
                this.classificationsSchema      = this.formSchema.classificationsSchema(this);
                this.consolidatedDocumentSchema = this.formSchema.consolidatedDocumentSchema(this);
                this.globalButtonsSchema        = this.formSchema.globalButtonsSchema(this);
            });
    }

}
