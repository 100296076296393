import { inject }             from 'aurelia-framework';
import { AppContainer }       from 'resources/services/app-container';
import { BaseFormViewModel }  from 'base-form-view-model';
import { DiplomasRepository } from 'modules/legislation/diplomas/services/repository';

@inject(AppContainer, DiplomasRepository)
export class ViewDiploma extends BaseFormViewModel {

    headerTitle = 'form.title.view-record';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.jurist.diplomas.manage',
            'legislation.jurist.diplomas.view',
            'legislation.consultant.diplomas.manage',
            'legislation.consultant.diplomas.view',
            'legislation.client.diplomas.manage',
            'legislation.client.diplomas.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        return this.repository.details(params.id).then((response) => this.diploma = response);
    }

    /**
     * Handles attached event
     */
    attached() {
        this.headerSubtitle = this.diploma.name;
    }

    /**
     * Returns back button route href
     *
     * @returns {string}
     */
    backButtonRouteHref() {
        return this.backButtonRoute
            ? this.appContainer.router.generate(this.backButtonRoute)
            : '#';
    }

}
