import { inject, transient }                from 'aurelia-framework';
import { BaseFilterFormSchema }             from 'resources/classes/base-filter-form-schema';
import { LocalStorage }                     from 'resources/services/local-storage';
import { EventAggregator }                  from 'aurelia-event-aggregator';
import { I18N }                             from 'aurelia-i18n';
import { DialogService }                    from 'aurelia-dialog';
import { CountriesRepository }              from 'modules/administration/countries/services/repository';
import { CompanyOrganizationsRepository }   from 'modules/administration/company-organizations/services/repository';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';
import { DiplomaStatusesRepository }        from 'modules/legislation/management/diploma-statuses/services/repository';
import { DiplomaTypesRepository }           from 'modules/legislation/management/diploma-types/services/repository';
import { SourcesRepository }                from 'modules/legislation/management/sources/services/repository';
import { TreeModal }                        from 'modules/administration/tree-modal/index';

@transient()
@inject(
    LocalStorage,
    EventAggregator,
    I18N,
    DialogService,
    CountriesRepository,
    CompanyOrganizationsRepository,
    DiplomaClassificationsRepository,
    DiplomaStatusesRepository,
    DiplomaTypesRepository,
    SourcesRepository,
)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param dialogService
     * @param countriesRepository
     * @param companyOrganizationsRepository
     * @param diplomaClassificationsRepository
     * @param diplomaStatusesRepository
     * @param diplomaTypesRepository
     * @param sourcesRepository
     */
    constructor(
        storage,
        eventAggregator,
        i18n,
        dialogService,
        countriesRepository,
        companyOrganizationsRepository,
        diplomaClassificationsRepository,
        diplomaStatusesRepository,
        diplomaTypesRepository,
        sourcesRepository,
    ) {
        super(storage, eventAggregator, i18n, dialogService);

        this.countriesRepository              = countriesRepository;
        this.companyOrganizationsRepository   = companyOrganizationsRepository;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
        this.diplomaStatusesRepository        = diplomaStatusesRepository;
        this.diplomaTypesRepository           = diplomaTypesRepository;
        this.sourcesRepository                = sourcesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Client}
     */
    model(viewModel) {
        this.modelDefaults.country_id = viewModel.settings.default_country_id || null;
        this.modelDefaults.source_id  = viewModel.settings.default_source_id ? [viewModel.settings.default_source_id] : [];

        return this.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns client data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.published_at_from = {
            type:     'material-ui-date-picker',
            key:      'published_at_from',
            label:    'form.field.published-at-from',
            size:     3,
            required: false,
            settings: {
                selectYears:  100,
                selectMonths: true,
            },
        };

        this.published_at_to = {
            type:     'material-ui-date-picker',
            key:      'published_at_to',
            label:    'form.field.published-at-to',
            size:     3,
            required: false,
            settings: {
                selectYears:  100,
                selectMonths: true,
            },
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.inserted-at-from',
            size:     3,
            required: false,
            settings: {
                selectYears:  100,
                selectMonths: true,
            },
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.inserted-at-to',
            size:     3,
            required: false,
            settings: {
                selectYears:  100,
                selectMonths: true,
            },
        };

        this.country_id = {
            type:         'select2',
            key:          'country_id',
            label:        'form.field.country',
            size:         3,
            remoteSource: this.countriesRepository.active.bind(this.countriesRepository),
            required:     false,
            observers:    [
                () => {
                    this.diploma_classification_id.instance.fetchData();
                    this.source_id.instance.fetchData();
                },
            ],
        };

        this.diploma_classification_id = {
            type:                   'diploma-classifications-select',
            key:                    'diploma_classification_id',
            label:                  'form.field.classification',
            size:                   6,
            remoteSource:           this.diplomaClassificationsRepository.activeByCountry.bind(this.diplomaClassificationsRepository),
            remoteSourceParameters: () => viewModel.filterModel.country_id,
            required:               false,
            inputGroup:             {
                position: 'right',
                button:   {
                    action: () => this.dialogService.open({
                        viewModel: TreeModal,
                        model:     {
                            repository: {
                                method: 'tree',
                                source: this.diplomaClassificationsRepository,
                            },
                            onClick:    (event, data) => viewModel.filterModel.diploma_classification_id = data.node.key,
                        },
                    }),
                    icon:   {
                        class: 'icon-tree5',
                    },
                },
            },
        };

        this.source_id = {
            type:                   'multiselect-native',
            key:                    'source_id',
            label:                  'form.field.source',
            size:                   3,
            remoteSource:           this.sourcesRepository.activeByCountry.bind(this.sourcesRepository),
            remoteSourceParameters: () => viewModel.filterModel.country_id,
            required:               false,
            observers:              [
                () => this.type_id.instance.fetchData(),
            ],
        };

        this.type_id = {
            type:                   'select2',
            key:                    'type_id',
            label:                  'form.field.type',
            size:                   3,
            remoteSource:           this.diplomaTypesRepository.activeBySources.bind(this.diplomaTypesRepository),
            remoteSourceParameters: () => viewModel.filterModel.source_id || [],
            required:               false,
        };

        this.number = {
            type:     'text',
            key:      'number',
            label:    'form.field.number',
            size:     3,
            required: false,
        };

        this.year = {
            type:     'number',
            key:      'year',
            label:    'form.field.year',
            size:     3,
            required: false,
        };

        this.applicable_sectors = {
            type:           'multiselect-native',
            key:            'applicable_sectors',
            label:          'form.field.applicable-sectors',
            size:           3,
            remoteSource:   this.companyOrganizationsRepository.active.bind(this.companyOrganizationsRepository),
            processResults: (item) => {
                item.name = item.acronym + ' - ' + item.name;

                return item;
            },
            required:       false,
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         3,
            remoteSource: this.diplomaStatusesRepository.active.bind(this.diplomaStatusesRepository),
            required:     false,
        };

        this.summary = {
            type:     'text',
            key:      'summary',
            label:    'form.field.summary',
            size:     3,
            required: false,
        };

        this.keyword = {
            type:     'text',
            key:      'keyword',
            label:    'form.field.keyword',
            size:     3,
            required: false,
        };

        this.applicable_id = {
            type:     'boolean-options-select',
            key:      'applicable_id',
            label:    'form.field.applicable',
            size:     3,
            hidden:   !viewModel.appContainer.authenticatedUser.isClient(),
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.published_at_from, this.published_at_to, this.created_at_from, this.created_at_to],
            [this.country_id, this.diploma_classification_id, this.source_id],
            [this.type_id, this.number, this.year, this.applicable_sectors],
            [this.summary, this.keyword, this.applicable_id, this.status_id],
            [this.buttons],
        ];
    }

}
