import { inject, transient }      from 'aurelia-framework';
import { BaseFilterFormSchema }   from 'resources/classes/base-filter-form-schema';
import { LocalStorage }           from 'resources/services/local-storage';
import { EventAggregator }        from 'aurelia-event-aggregator';
import { I18N }                   from 'aurelia-i18n';
import { DialogService }          from 'aurelia-dialog';
import { DiplomaTypesRepository } from 'modules/legislation/management/diploma-types/services/repository';

@transient()
@inject(LocalStorage, EventAggregator, I18N, DialogService, DiplomaTypesRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        type_id:      null,
        number:       null,
        published_at: null,
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param dialogService
     * @param diplomaTypesRepository
     */
    constructor(storage, eventAggregator, i18n, dialogService, diplomaTypesRepository) {
        super(storage, eventAggregator, i18n, dialogService);

        this.diplomaTypesRepository = diplomaTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {{}}
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns client data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.type_id = {
            type:         'select2',
            key:          'type_id',
            id:           'legal_connection_diploma_type_id',
            label:        'form.field.diploma-type',
            size:         4,
            remoteSource: this.diplomaTypesRepository.all.bind(this.diplomaTypesRepository),
        };

        this.number = {
            type:  'text',
            key:   'number',
            id:    'legal_connection_diploma_number',
            label: 'form.field.number',
            size:  2,
        };

        this.year = {
            type:  'text',
            key:   'year',
            id:    'legal_connection_diploma_year',
            label: 'form.field.year',
            size:  2,
        };

        this.published_at = {
            type:     'material-ui-date-picker',
            key:      'published_at',
            id:       'legal_connection_diploma_published_at',
            label:    'form.field.published-at',
            size:     4,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId:   viewModel.listingId,
                criteria:    viewModel.filterModel,
                storeFilter: false,
            }),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.type_id, this.number, this.year, this.published_at],
            [this.buttons],
        ];
    }

}
