import { inject, transient }                from 'aurelia-framework';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';

@transient()
@inject(DiplomaClassificationsRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        classifications: [],
    };

    /**
     * Constructor
     *
     * @param diplomaClassificationsRepository
     */
    constructor(diplomaClassificationsRepository) {
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {{}}
     */
    model() {
        return this.modelDefaults;
    }

    /**
     * Returns preparation form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.classifications = {
            type:         'fancytree',
            key:          'classifications',
            label:        'form.field.classifications',
            size:         12,
            options:      [],
            remoteSource: this.diplomaClassificationsRepository.tree.bind(this.diplomaClassificationsRepository, true),
            settings:     {
                filterable:       true,
                repository:       this.diplomaClassificationsRepository,
                treeNodeSelected: (event, data) => {
                    let detailedClassifications = [];

                    let selectedNodes = data.tree.getSelectedNodes();

                    for (let i = 0; i < selectedNodes.length; i++) {
                        detailedClassifications.push({
                            id:   selectedNodes[i].key,
                            name: this.nodePath(selectedNodes[i]),
                        });
                    }

                    viewModel.detailedClassifications.splice(0, viewModel.detailedClassifications.length, ...detailedClassifications);
                },
            },
        };

        return [
            [this.classifications],
        ];
    }

    /**
     * Returns full path of a node
     *
     * @param node
     *
     * @returns {string}
     */
    nodePath(node) {
        if (node.title === 'root') {
            return '';
        }

        let parentTitle = this.nodePath(node.parent);

        return (parentTitle ? (parentTitle + '<icon class="icon-arrow-right22"></icon>') : '') + node.title;
    }

}
