import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { CreateDiplomaLegalConnection }  from 'modules/legislation/diplomas/legal-connections/create/index';
import { EditDiplomaLegalConnection }    from 'modules/legislation/diplomas/legal-connections/edit/index';
import { ViewDiplomaModal }              from 'modules/legislation/diplomas/view-modal/index';
import { DialogService }                 from 'aurelia-dialog';

@inject(AppContainer, DialogService)
export class ListDiplomaLegalConnectionsCustomElement {

    listingId = 'legislation-diplomas-legal-connections-listing';

    @bindable({defaultBindingMode: bindingMode.twoWay}) readonly;
    @bindable({defaultBindingMode: bindingMode.twoWay}) diploma;

    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     */
    constructor(appContainer, dialogService) {
        this.appContainer  = appContainer;
        this.dialogService = dialogService;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        let currentTenant = this.appContainer.authenticatedUser.currentTenant();

        this.datatable = {
            resource:           'resource.legislation.legal-connection',
            repository:         {
                search:  (criteria) => Promise.resolve({
                    data:            this.diploma.legal_connections,
                    recordsTotal:    this.diploma.legal_connections.length,
                    recordsFiltered: this.diploma.legal_connections.length,
                }),
                destroy: null,
            },
            destroySelected:    false,
            actions:            [
                {
                    icon:    'icon-file-eye',
                    action:  (row) => this.dialogService.open({
                        viewModel: ViewDiplomaModal,
                        model:     {
                            id: row.owned_diploma_id,
                        },
                    }),
                    tooltip: 'form.button.view',
                    visible: (row) => row.owned_diploma_id > 0,
                },
                {
                    icon:    'icon-pencil7',
                    action:  (row) => this.dialogService.open({
                        viewModel: EditDiplomaLegalConnection,
                        model:     {
                            diploma:         this.diploma,
                            legalConnection: row,
                        },
                    }),
                    tooltip: 'form.button.edit',
                    visible: (row) => currentTenant ? row.client_id === currentTenant.id : true,
                },
                {
                    icon:    'icon-unlink2',
                    action:  (row, $index) => this.unlink($index),
                    tooltip: 'form.button.unlink',
                },
            ],
            options:            [],
            buttons:            [
                {
                    label:  'form.button.create-new',
                    icon:   'icon-plus3',
                    action: () => this.dialogService.open({
                        viewModel: CreateDiplomaLegalConnection,
                        model:     this.diploma,
                    }),
                    color:  'success',
                },
            ],
            sorting:            {
                column:    3,
                direction: 'desc',
            },
            selectable:         false,
            showFilter:         false,
            showResultsPerPage: false,
            showInfo:           false,
            showPagination:     false,
            columns:            [
                {
                    data:      'legal_connection_type_name',
                    name:      'legal_connection_type_name',
                    title:     'form.field.legal-connection-type',
                    orderable: false,
                },
                {
                    data:      'owned_diploma_name',
                    name:      'owned_diploma_name',
                    title:     'form.field.diploma',
                    orderable: false,
                },
                {
                    data:      'commencement_date',
                    name:      'commencement_date',
                    title:     'form.field.commencement-date',
                    orderable: false,
                },
                {
                    data:      'notes',
                    name:      'notes',
                    title:     'form.field.notes',
                    orderable: false,
                },
            ],
        };
    }

    /**
     * Removes a service record from the services array
     *
     * @param index
     */
    unlink(index) {
        this.diploma.legal_connections.splice(index, 1);
    }
}
