import { transient } from 'aurelia-framework';
import { Diploma }   from 'modules/legislation/models/diploma';

@transient()
export class FormSchema {
    /**
     * Returns a new instance of the model
     *
     * @returns {{}}
     */
    model() {
        return new Diploma();
    }

    /**
     * Returns preparation form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.internal_code = {
            type:     'textarea',
            key:      'internal_code',
            label:    'form.field.internal-code',
            size:     4,
            required: false,
        };

        this.keywords = {
            type:     'tags',
            key:      'keywords',
            label:    'form.field.keywords',
            size:     12,
            required: false,
        };

        this.resume = {
            type:     'textarea',
            key:      'resume',
            label:    'form.field.resume',
            size:     12,
            required: false,
        };

        this.notes = {
            type:     'textarea',
            key:      'notes',
            label:    'form.field.notes',
            size:     12,
            required: false,
        };

        return [
            [this.internal_code],
            [this.keywords],
            [this.resume],
            [this.notes],
        ];
    }

}
