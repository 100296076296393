import { inject }                         from 'aurelia-framework';
import { DiplomaLegalConnection }         from 'modules/legislation/models/diploma-legal-connection';
import { LegalConnectionTypesRepository } from 'modules/legislation/management/legal-connection-types/services/repository';

@inject(LegalConnectionTypesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param legalConnectionTypesRepository
     */
    constructor(legalConnectionTypesRepository) {
        this.legalConnectionTypesRepository = legalConnectionTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {DiplomaLegalConnection}
     */
    model() {
        let model = new DiplomaLegalConnection();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.owned_diploma_name = {
            type:       'text',
            key:        'owned_diploma_name',
            id:         'diploma_legal_connections_owned_diploma_name',
            label:      'form.field.diploma',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.legal_connection_type_id = {
            type:         'select2',
            key:          'legal_connection_type_id',
            id:           'diploma_legal_connections_legal_connection_type_id',
            label:        'form.field.connection-type',
            size:         6,
            remoteSource: this.legalConnectionTypesRepository.active.bind(this.legalConnectionTypesRepository),
        };

        this.commencement_date = {
            type:     'material-ui-date-picker',
            key:      'commencement_date',
            id:       'diploma_legal_connections_commencement_date',
            label:    'form.field.commencement-date',
            size:     6,
            required: false,
            settings: {
                selectYears:  100,
                selectMonths: true,
            },
        };

        this.notes = {
            type:     'textarea',
            key:      'notes',
            id:       'diploma_legal_connections_notes',
            label:    'form.field.notes',
            size:     12,
            required: false,
        };

        this.manual_owned_diploma = {
            type:       'textarea',
            key:        'manual_owned_diploma',
            id:         'manual_owned_diploma',
            label:      'form.field.if-you-dont-find-diploma-name-insert-manually-here',
            size:       12,
            required:   false,
            attributes: {
                hidden: true,
            },
        };

        return [
            [this.owned_diploma_name],
            [this.legal_connection_type_id, this.commencement_date],
            [this.notes],
            [this.manual_owned_diploma],
        ];
    }

}
