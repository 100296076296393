import { inject } from 'aurelia-framework';
import { I18N }   from 'aurelia-i18n';

@inject(I18N)
export class DiplomaSummary {

    /**
     * Constructor
     *
     * @param i18n
     */
    constructor(i18n) {
        this.i18n = i18n;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.column  = model.column;
        this.diploma = model.row;
    }

}
