import { bindable, inject, transient } from 'aurelia-framework';
import { AppContainer }                from 'resources/services/app-container';
import { BaseViewModel }               from 'base-view-model';
import { DiplomasRepository }          from 'modules/legislation/diplomas/services/repository';
import { FormSchema }                  from 'modules/legislation/diplomas/edit/classifications/form-schema';
import { DialogController }            from 'aurelia-dialog';
import collect                         from 'collect.js';

@transient()
@inject(AppContainer, DiplomasRepository, FormSchema, DialogController)
export class EditDiplomaClassificationsModal extends BaseViewModel {

    @bindable settings = {
        title: 'form.title.edit-classifications',
        size:  'modal-full',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param dialogController
     */
    constructor(appContainer, repository, formSchema, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.formSchema       = formSchema;
        this.dialogController = dialogController;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.jurist.diplomas.manage',
            'legislation.jurist.diplomas.edit',
            'legislation.consultant.diplomas.manage',
            'legislation.consultant.diplomas.edit',
            'legislation.client.diplomas.manage',
            'legislation.client.diplomas.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(diploma, navigationInstruction) {
        super.activate(navigationInstruction);

        this.diploma = diploma;

        // defines diploma name as the modal subtitle
        this.settings.subtitle = this.diploma.name;

        return this.fetchData().then(() => {
            this.model  = this.formSchema.model();
            this.schema = this.formSchema.schema(this);

            // clone detailed classifications
            this.detailedClassifications = [...this.diploma.detailed_classifications];

            this.model.classifications.splice(0, this.model.classifications.length, ...collect(this.detailedClassifications).pluck('id').all());
        });
    }

    /**
     * Submits form
     */
    submit() {
        return this.repository.updateClassifications(this.diploma.id, this.model).then((response) => {
            // creates a new alert message
            this.alert = this.alertMessage(response.status, response.message, response.errors);

            if (response.status === true) {
                this.diploma.detailed_classifications.splice(0, this.diploma.detailed_classifications.length, ...this.detailedClassifications);
                this.appContainer.notifier.successNotice();
            }

            return response.status;
        });
    }

}
