import { bindable, bindingMode, inject }   from 'aurelia-framework';
import { AuthenticatedUser }               from 'resources/services/authenticated-user';
import { DiplomasRepository }              from 'modules/legislation/diplomas/services/repository';
import { EditDiplomaClassificationsModal } from 'modules/legislation/diplomas/edit/classifications/index';
import { EditClientDiplomaDetailsModal }   from 'modules/legislation/diplomas/edit/details/index';
import { DialogService }                   from 'aurelia-dialog';
import collect                             from 'collect.js';

@inject(AuthenticatedUser, DiplomasRepository, DialogService)
export class DiplomaDetailsCustomElement {

    @bindable readonly = false;

    @bindable({defaultBindingMode: bindingMode.twoWay}) diploma;

    /**
     * Constructor
     *
     * @param authenticatedUser
     * @param repository
     * @param dialogService
     */
    constructor(authenticatedUser, repository, dialogService) {
        this.authenticatedUser = authenticatedUser;
        this.repository        = repository;
        this.dialogService     = dialogService;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.groupedLegalConnections = collect(this.diploma.legal_connections).groupBy((item, key) => item.legal_connection_type.name);
    }

    /**
     * Opens `edit classifications` modal
     */
    editClassifications() {
        this.dialogService.open({
            viewModel: EditDiplomaClassificationsModal,
            model:     this.diploma,
        });
    }

    /**
     * Opens `edit details and keywords` modal
     */
    editDetails() {
        this.dialogService.open({
            viewModel: EditClientDiplomaDetailsModal,
            model:     this.diploma,
        });
    }

    canEdit() {
        let permissions = [
            'legislation.jurist.diplomas.manage',
            'legislation.jurist.diplomas.edit',
            'legislation.consultant.diplomas.manage',
            'legislation.consultant.diplomas.edit',
            'legislation.client.diplomas.manage',
            'legislation.client.diplomas.edit',
        ];

        return this.authenticatedUser.hasCurrentTenant() && this.authenticatedUser.can(permissions) && !this.readonly;
    }

}
