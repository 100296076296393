import { bindable, inject, PLATFORM } from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseViewModel }              from 'base-view-model';
import { DiplomasRepository }         from 'modules/legislation/diplomas/services/repository';
import { DiplomaSettingsRepository }  from 'modules/legislation/management/diploma-settings/services/repository';
import { ViewDiplomaModal }           from 'modules/legislation/diplomas/view-modal/index';
import { FilterFormSchema }           from 'modules/legislation/diplomas/filter-form-schema';
import { DialogService }              from 'aurelia-dialog';

@inject(AppContainer, DiplomasRepository, DiplomaSettingsRepository, DialogService, FilterFormSchema)
export class ListDiplomas extends BaseViewModel {

    headerTitle = 'listing.legislation.diplomas';
    listingId   = 'legislation-diplomas-listing';

    @bindable repository;
    @bindable datatable;

    settings = {
        country_id: null,
        source_id:  null,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param diplomaSettingsRepository
     * @param dialogService
     * @param filterFormSchema
     */
    constructor(appContainer, repository, diplomaSettingsRepository, dialogService, filterFormSchema) {
        super(appContainer);

        this.repository                = repository;
        this.diplomaSettingsRepository = diplomaSettingsRepository;
        this.dialogService             = dialogService;
        this.filterFormSchema          = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.jurist.diplomas.manage',
            'legislation.jurist.diplomas.view',
            'legislation.consultant.diplomas.manage',
            'legislation.consultant.diplomas.view',
            'legislation.client.diplomas.manage',
            'legislation.client.diplomas.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.defineDatatable();

        return this.fetchData().then(() => {
            this.filterSchema = this.filterFormSchema.schema(this);
            this.filterModel  = this.filterFormSchema.model(this);

            this.filterModel.number = params.Number ? params.Number : this.filterModel.number;
            this.filterModel.year   = params.Year ? params.Year : this.filterModel.year;
        });
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:           'resource.legislation.diploma',
            repository:         this.repository,
            edit:               null,
            destroy:            null,
            show:               {
                action: (row) => this.dialogService.open({
                    viewModel: ViewDiplomaModal,
                    model:     row,
                }),
            },
            actionsContextMenu: true,
            actions:            [
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.diploma',
                    visible: (row) => row.viewing_document_file_id !== null,
                    action:  (row) => this.repository.downloadFile(row.viewingDocument),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.consolidated-document',
                    visible: (row) => row.consolidated_document_file_id !== null,
                    action:  (row) => this.repository.downloadFile(row.consolidatedDocument),
                },
            ],
            options:            [
                {
                    label:     'form.button.export-to-excel',
                    icon:      'icon-file-excel',
                    className: 'btn bg-success',
                    action:    () => this.repository.exportListingExcel(this.filterModel),
                },
            ],
            buttons:            [],
            selectable:         true,
            sorting:            {
                column:    3,
                direction: 'desc',
            },
            columns:            [
                {
                    data:      'name',
                    name:      'diplomas.name',
                    title:     'form.field.diploma',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/diploma-name/index'),
                    nowrap:    true,
                },
                {
                    data:       'summary',
                    name:       'diplomas.summary',
                    title:      'form.field.summary',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/diploma-summary/index'),
                    orderable:  false,
                },
                {
                    data:       'legal_connections',
                    name:       'legal_connections',
                    title:      'form.field.legal-connections',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/diploma-legal-connections/index'),
                    nowrap:     true,
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:            'created_at',
                    name:            'diplomas.created_at',
                    title:           'form.field.inserted-at',
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
                {
                    data:  'status',
                    name:  'diploma_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {*}
     */
    fetchData(params = null) {
        return this.diplomaSettingsRepository.settings().then((response) => this.settings = response);
    }

}
