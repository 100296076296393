import { bindable, inject }      from 'aurelia-framework';
import { AppContainer }          from 'resources/services/app-container';
import { BaseFormViewModel }     from 'base-form-view-model';
import { PlanActionsRepository } from './services/repository';
import { DialogController }      from 'aurelia-dialog';

@inject(AppContainer, PlanActionsRepository, DialogController)
export class ActionPlanListing extends BaseFormViewModel {

    @bindable settings = {
        title:   'form.title.action-plan',
        size:    'modal-full',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     */
    constructor(appContainer, repository, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
    }

    /**
     * Handles bind event
     */
    activate(diplomaApplicability) {
        this.diplomaApplicability = diplomaApplicability;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search: (criteria) => this.repository.search(this.diplomaApplicability.id, criteria),
            },
            destroy:             null,
            destroySelected:     null,
            actions:             [],
            options:             [],
            buttons:             [],
            selectable:          false,
            showOptionsDropdown: false,
            showInfo:            false,
            showPagination:      false,
            sorting:             {
                column:    1,
                direction: 'asc',
            },
            columns:             [
                {
                    data:  'order',
                    name:  'plan_actions.order',
                    title: 'form.field.order',
                },
                {
                    data:  'description',
                    name:  'plan_actions.description',
                    title: 'form.field.description',
                },
                {
                    data:  'periodicity',
                    name:  'time_intervals.name',
                    title: 'form.field.periodicity',
                },
                {
                    data:  'first_task_implementation_deadline',
                    name:  'plan_actions.first_task_implementation_deadline',
                    title: 'form.field.implementation-deadline(1st-task)',
                },
            ],
        };
    }

}
