import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';
import { Downloader }       from 'resources/services/downloader';
import { GlobalConfig }     from 'resources/configs/global-config';

@inject(CustomHttpClient, Downloader)
export class DiplomasRepository {

    /**
     * Constructor
     *
     * @param httpClient
     * @param downloader
     */
    constructor(httpClient, downloader) {
        this.httpClient = httpClient;

        this.downloader = downloader;
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('legislation/diplomas/search', criteria);
    }

    /**
     * Exports listing excel
     */
    exportListingExcel(criteria) {
        return this.httpClient.rawPost('legislation/diplomas/export-listing', criteria)
            .then((response) => response.blob())
            .then(result => this.downloader.download(result, 'diplomas_listing', 'xlsx'));
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post('legislation/diplomas', data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`legislation/diplomas/${id}`);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    details(id) {
        return this.httpClient.get(`legislation/diplomas/${id}/details`);
    }

    /**
     * Fetches diploma conformity evaluations
     *
     * @param id
     *
     * @returns {*}
     */
    conformityEvaluations(id) {
        return this.httpClient.get(`legislation/diplomas/${id}/conformity-evaluations`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`legislation/diplomas/${id}`, data);
    }

    /**
     * Updates details
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    updateClientDiplomaDetails(id, data) {
        return this.httpClient.patch(`legislation/diplomas/${id}/client-details`, data);
    }

    /**
     * Updates classifications
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    updateClassifications(id, data) {
        return this.httpClient.patch(`legislation/diplomas/${id}/classifications`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`legislation/diplomas/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('legislation/diplomas/destroy-selected', {ids: ids});
    }

    /**
     * Returns the file upload uri
     */
    fileUploadUri() {
        return GlobalConfig.generateApiUrl('legislation/diplomas/files/upload');
    }

    /**
     * Opens a file
     *
     * @param file
     */
    openFile(file) {
        this.getFile(file.id)
            .then(result => this.downloader.open(result, file.display_name, file.extension));
    }

    /**
     * Downloads a file
     *
     * @param file
     */
    downloadFile(file) {
        this.getFile(file.id)
            .then(result => this.downloader.download(result, file.display_name, file.extension));
    }

    /**
     * Gets a file
     *
     * @param id
     *
     * @returns {*}
     */
    getFile(id) {
        return this.httpClient.rawGet(`legislation/diplomas/files/${id}/preview`)
            .then((response) => response.blob());
    }

    /**
     * Downloads diploma sheet
     *
     * @param id
     *
     * @returns {*}
     */
    downloadDiplomaSheet(id) {
        return this.httpClient.rawGet(`legislation/diplomas/${id}/sheet`)
            .then((response) => response.blob())
            .then(result => this.downloader.download(result, 'diploma_sheet', 'pdf'));
    }

}
