import { bindable, inject }                from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseFormViewModel }               from 'base-form-view-model';
import { DiplomasRepository }              from 'modules/legislation/diplomas/services/repository';
import { FilterFormSchema }                from 'modules/legislation/diplomas/legal-connections/create/filter-form-schema';
import { FormSchema }                      from 'modules/legislation/diplomas/legal-connections/create/form-schema';
import { ViewDiplomaModal }                from 'modules/legislation/diplomas/view-modal/index';
import { DialogController, DialogService } from 'aurelia-dialog';
import collect                             from 'collect.js';

@inject(AppContainer, DiplomasRepository, FilterFormSchema, FormSchema, DialogService, DialogController)
export class CreateDiplomaLegalConnection extends BaseFormViewModel {

    listingId = 'legislation-diploma-legal-connections-diplomas-listing';

    @bindable settings = {
        title:   'form.title.create-new-legal-connection',
        size:    'modal-full',
        buttons: false,
    };

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param dialogController
     * @param repository
     * @param filterFormSchema
     * @param formSchema
     */
    constructor(appContainer, repository, filterFormSchema, formSchema, dialogService, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
        this.formSchema       = formSchema;
        this.dialogService    = dialogService;
        this.dialogController = dialogController;
    }

    /**
     * Handles bind event
     */
    activate(diploma) {
        this.diploma = diploma;

        this.model        = this.formSchema.model(this);
        this.schema       = this.formSchema.schema(this);
        this.bottomSchema = this.formSchema.bottomSchema(this);

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);

        if (this.diploma.name) {
            this.settings.title = `${this.diploma.name} - ${this.appContainer.i18n.tr('form.title.create-new-legal-connection')}`;
        }

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            searchImmediately: false,
            repository:        this.repository,
            destroySelected:   false,
            actions:           [
                {
                    icon:    'icon-file-eye',
                    action:  (row) => this.dialogService.open({
                        viewModel: ViewDiplomaModal,
                        model:     row,
                    }),
                    tooltip: 'form.button.view',
                },
                {
                    icon:    'icon-link2',
                    action:  (row) => this.link(row),
                    tooltip: 'form.button.link',
                },
            ],
            options:           [],
            buttons:           [],
            sorting:           {
                column:    0,
                direction: 'asc',
            },
            selectable:        false,
            rowFilter:         (row) => row.id !== this.diploma.id,
            columns:           [
                {
                    data:   'name',
                    name:   'diplomas.name',
                    title:  'form.field.diploma',
                    nowrap: true,
                },
                {
                    data:  'summary',
                    name:  'diplomas.summary',
                    title: 'form.field.summary',
                },
            ],
        };
    }

    /**
     * Establishes a connection with a given diploma
     *
     * @param diploma
     */
    link(diploma) {
        // resets the alert message
        this.alert = null;

        if (this._validateForm(diploma)) {
            let connection = this.formSchema.model();

            connection.legal_connection_type_id   = this.model.legal_connection_type_id;
            connection.legal_connection_type_name = this.dropdownSelectedText('diploma_legal_connections_legal_connection_type_id');
            connection.owner_diploma_id           = this.diploma.id;
            connection.owned_diploma_id           = diploma.id;
            connection.owned_diploma_name         = diploma.name;
            connection.commencement_date          = this.model.commencement_date;
            connection.notes                      = this.model.notes;
            connection.manual_owned_diploma       = null;

            this.diploma.legal_connections.push(connection);

            this.appContainer.notifier.successNotice();
            this.dialogController.ok();
        }
    }

    /**
     * Creates a connection with a manual diploma name
     */
    create() {
        let connection = this.formSchema.model();

        connection.legal_connection_type_id   = this.model.legal_connection_type_id;
        connection.legal_connection_type_name = this.dropdownSelectedText('diploma_legal_connections_legal_connection_type_id');
        connection.owner_diploma_id           = this.diploma.id;
        connection.owned_diploma_id           = null;
        connection.owned_diploma_name         = this.model.manual_owned_diploma;
        connection.commencement_date          = this.model.commencement_date;
        connection.notes                      = this.model.notes;
        connection.manual_owned_diploma       = this.model.manual_owned_diploma;

        this.diploma.legal_connections.push(connection);

        this.appContainer.notifier.successNotice();
        this.dialogController.ok();
    }

    /**
     * Validates form
     *
     * TODO: this is temporary!!!
     *
     * @private
     */
    _validateForm(diploma) {
        if (!isNumeric(this.model.legal_connection_type_id)) {
            // creates a new alert message
            this.alert = this.alertMessage(false, 'message.failure.please-select-legal-connection-type');

            return false;
        }

        let connectionExists = collect(this.diploma.legal_connections).contains((connection) => {
            return connection.owned_diploma_id === diploma.id && connection.legal_connection_type_id === this.model.legal_connection_type_id;
        });

        if (connectionExists) {
            // creates a new alert message
            this.alert = this.alertMessage(false, 'message.failure.pretended-connection-already-exists');

            return false;
        }

        return true;
    }

}
